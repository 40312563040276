import AOS from 'aos';
import Swiper from 'swiper/bundle';
import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';

window.addEventListener('load', function () {
  AOS.init();
  
  const searchDate = document.getElementById('searchDate');
  if(searchDate) {
    const rangepicker = new DateRangePicker(searchDate, {
      format: 'yyyy-mm-dd',
      todayButton: true,
      todayButtonMode: 1,
      todayBtnMode: 1,
      todayHighlight: true
    });
  }
});



const imageSwiper = new Swiper(".image-swiper", {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 0,
  centeredSlides: true,
  autoHeight: true,
  slideToClickedSlide: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
});

const quoteSwiper = new Swiper(".quote-swiper", {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 48,
  centeredSlides: true,
  autoHeight: true,
  slideToClickedSlide: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
});


var openmodal = document.querySelectorAll('.modal-open')

for (var i = 0; i < openmodal.length; i++) {
  openmodal[i].addEventListener('click', function(event){
  event.preventDefault()
  toggleModal(event.target.dataset.modal)
  })
}

const overlays = document.querySelectorAll('.modal-overlay')
overlays.forEach(function(item) {
  item.addEventListener('click', toggleModal);
})

var closemodal = document.querySelectorAll('.modal-close')
for (var i = 0; i < closemodal.length; i++) {
  closemodal[i].addEventListener('click', toggleModal)
}

document.onkeydown = function(evt) {
  evt = evt || window.event
  var isEscape = false
  if ("key" in evt) {
  isEscape = (evt.key === "Escape" || evt.key === "Esc")
  } else {
  isEscape = (evt.keyCode === 27)
  }
  if (isEscape && document.body.classList.contains('modal-active')) {
  toggleModal()
  }
}
  
function toggleModal (modalId) {
  const body = document.querySelector('body');
  let modal = document.getElementById(modalId);
  let modals = document.querySelectorAll('.modal');
  
  if(document.body.classList.contains('modal-active')) {
    modals.forEach(function(item) {
      item.classList.add('opacity-0');
      item.classList.add('pointer-events-none');
    })
    body.classList.remove('modal-active');
  } else {
    modal.classList.remove('opacity-0');
    modal.classList.remove('pointer-events-none');
    body.classList.add('modal-active');
  }
  
}
